// Prevents automatic submission of form
document.addEventListener('turbolinks:load', function() {
  const preventFormSubmission = document.querySelector('[data-behavior*="prevent-form-submission"]')

  if (preventFormSubmission) {
    // Prevent form submission on form submission
    preventFormSubmission.addEventListener('submit', function(event) {
      event.preventDefault()

      return false
    }, true)

    // Prevent form submission on `enter` keypress
    preventFormSubmission.addEventListener('keypress', function(event) {
      if (event.keyCode === 13) {
        event.preventDefault()

        return false
      }
    }, true)
  }
})
