import { setValueFromPercent } from 'helpers/set-value-from-percentage'
import formatCurrency          from 'helpers/new-format-currency'

/**
 * @param {event} event
 * @param {object} sliderBasedOnValue
 * @param {object} sliderFormattedResults
 * @param {object} sliderResultsHiddenField
 * @param {object} sliderProcessedResults
 */
function updatePercentageValue(event, sliderBasedOnValue, sliderFormattedResults, sliderResultsHiddenField, sliderProcessedResults) {
  if (sliderFormattedResults && event.target.value) {
    sliderFormattedResults.innerText = event.target.value + '%'
  }

  if (sliderResultsHiddenField && event.target.value) {
    sliderResultsHiddenField.value = setValueFromPercent(sliderBasedOnValue, event.target.value)
  }

  if (sliderBasedOnValue && event.target.value) {
    sliderProcessedResults.innerText = formatCurrency(setValueFromPercent(sliderBasedOnValue, event.target.value))
  }
}

document.addEventListener('turbolinks:load', function() {
  const percentageSliders = document.querySelectorAll('[data-behavior="percentage-slider"]')

  if (!percentageSliders) {
    return false
  }

  Array.from(percentageSliders).forEach(function(percentageSlider) {
    const sliderFormattedResults = percentageSlider.parentElement.querySelector('[data-behavior="slider-formatted-results"]')
    const sliderResultsHiddenField = percentageSlider.parentElement.querySelector('[data-behavior="slider-results"]')
    const sliderProcessedResults = percentageSlider.parentElement.querySelector('[data-behavior="slider-processed-results"]')
    const defaultRangeSliderValue = percentageSlider.value
    let sliderBasedOnValue = 0

    sliderFormattedResults.innerText = defaultRangeSliderValue + '%'
    percentageSlider.value = defaultRangeSliderValue

    // Percentage based on previous numeric results
    const sliderBasedOnElement = percentageSlider.parentElement.querySelector('[data-slider-based-on]')

    if (sliderBasedOnElement) {
      const targetPriceSlider = sliderBasedOnElement.dataset.sliderBasedOn
      let sliderBasedOnField

      if (targetPriceSlider && (sliderBasedOnField = document.querySelector('[name*=' + targetPriceSlider + ']'))) {
        sliderBasedOnValue = sliderBasedOnField.value
        sliderResultsHiddenField.value = setValueFromPercent(sliderBasedOnValue, defaultRangeSliderValue)

        sliderBasedOnField.addEventListener('input', function(event) {
          sliderBasedOnValue = event.target.value

          sliderProcessedResults.innerText = formatCurrency(setValueFromPercent(sliderBasedOnValue, percentageSlider.value))
          sliderResultsHiddenField.value = setValueFromPercent(sliderBasedOnValue, percentageSlider.value)
        })
        sliderBasedOnField.addEventListener('change', function(event) {
          sliderBasedOnValue = event.target.value

          sliderProcessedResults.innerText = formatCurrency(setValueFromPercent(sliderBasedOnValue, percentageSlider.value))
          sliderResultsHiddenField.value = setValueFromPercent(sliderBasedOnValue, percentageSlider.value)
        })

        sliderProcessedResults.innerText = formatCurrency(setValueFromPercent(sliderBasedOnValue, defaultRangeSliderValue))
      }
    }

    percentageSlider.addEventListener('input', function(event) {
      updatePercentageValue(event, sliderBasedOnValue, sliderFormattedResults, sliderResultsHiddenField, sliderProcessedResults)
    })
    percentageSlider.addEventListener('change', function(event) {
      updatePercentageValue(event, sliderBasedOnValue, sliderFormattedResults, sliderResultsHiddenField, sliderProcessedResults)
    })
  })
})
