// Base webpacker setup
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Turbolinks
import Turbolinks from 'turbolinks'
Turbolinks.start()

// Rails
import Rails from 'rails-ujs'
Rails.start()

// Google Tag Manager
import AfcGtm from 'classes/afc-gtm'
AfcGtm.start()

// Input masking
import { InputMasking } from 'classes/input-masking'
InputMasking.start()

// Other
import 'helpers/set-value-from-percentage'

import turbolinksScroll from 'helpers/turbolinks-scroll'
turbolinksScroll.patch(Turbolinks)

// Behaviors (DEPRECATED, use Stimulus Controllers where appropriate)
import 'behaviors/capture-borrower-timezone-offset'
import 'behaviors/character-count.js'
import 'behaviors/collection-expand'
import 'behaviors/content-expand'
import 'behaviors/maybe-form-marketing-values'
import 'behaviors/percentage-slider'
import 'behaviors/prevent-form-submission'
import 'behaviors/price-slider'
import 'behaviors/refresh-xhr-form'
import 'behaviors/reveal-password-toggle'
import 'behaviors/scroll-into-view'
import 'behaviors/scroll-to-top'
import 'behaviors/scroll-y-to'
import 'behaviors/toggle-class-on-inactivity'

import 'global-listeners/persist-marketing-values'
import 'global-listeners/tab-listener'

// import 'vendor/tippy'
import debounced from 'debounced'
debounced.initialize()
import 'vendor/stimulus-main'
