import { Controller } from 'stimulus'

/**
 * Manages js for header (StimulusJS controller)
 *
 * @class HeaderController
 * @memberof Controllers
 *
 */
export default class extends Controller {
  static targets = [
    'burglerButton',
    'burglerContainer',
    'headerLinks',
    'mobilePhoneSubMenu',
    'phoneButton',
    'wrapper',
    'link',
  ]

  /**
   * @function Controllers.HeaderController.handleSubMenuClick
   * @memberof Controllers
   * @description Handles sub menu click, toggles classes and attributes
   *
   * @param {Event} event - Mouse event
   *
   * @return {undefined} Manipulates DOM
   *
   */
  handleSubMenuClick(event) {
    event.preventDefault()

    const menuOpen = event.target.getAttribute('aria-expanded')
    event.target.setAttribute('aria-expanded', !(menuOpen === 'true'))
    event.target.parentElement.classList.toggle('header__link-container--sub-menu-active')
  }

  /**
   * @function Controllers.HeaderController.handleSubMenuMouseIn
   * @memberof Controllers
   * @description Handles sub menu click, toggles classes and attributes
   *
   * @param {Event} event - Mouse event
   *
   * @return {undefined} Manipulates DOM
   *
   */
  handleSubMenuMouseIn(event) {
    if (window.innerWidth <= 750) { return }

    const link = event.target.querySelector('.header__link')
    const menuOpen = link.getAttribute('aria-expanded')
    link.setAttribute('aria-expanded', !(menuOpen === 'true'))

    event.target.classList.add('header__link-container--sub-menu-active')
  }

  /**
   * @function Controllers.HeaderController.handleSubMenuLinkClick
   * @memberof Controllers
   * @description Closes the open sub-menu before allowing navigation. This
   * prevents a flash of open sub-menu upon the load of the new page.
   *
   * @param {Event} event - Mouse event
   *
   * @return {undefined} Manipulates DOM
   *
   */
  handleSubMenuLinkClick(event) {
    const menu = event.target.closest('.header__link-container--sub-menu')
    const link = menu.querySelector('.header__link')
    link.setAttribute('aria-expanded', false)
    menu.classList.remove('header__link-container--sub-menu-active')
    return true
  }

  /**
   * @function Controllers.HeaderController.handleSubMenuMouseOut
   * @memberof Controllers
   * @description Handles sub menu click, toggles classes and attributes
   *
   * @param {Event} event - Mouse event
   *
   * @return {undefined} Manipulates DOM
   *
   */
  handleSubMenuMouseOut(event) {
    if (window.innerWidth <= 750) { return }

    const link = event.target.querySelector('.header__link')
    link.setAttribute('aria-expanded', false)

    event.target.classList.remove('header__link-container--sub-menu-active')
  }

  /**
   * @function Controllers.HeaderController.handleMobileScrim
   * @memberof Controllers
   * @description Handles mobile scrim touch, toggles the menu closed.
   *
   * @return {undefined} Manipulates DOM
   *
   */
  handleMobileScrim() {
    this._toggleBurgler()
  }

  /**
   * @function Controllers.HeaderController.handleMobilePhone
   * @memberof Controllers
   * @description Toggles the mobile phone menu for a given duration.
   *
   * @param {Event} event - Touch event
   *
   * @return {undefined} Manipulates DOM
   *
   */
  handleMobilePhone(event) {
    if (window.innerWidth > 750) { return true }

    event.preventDefault()
    clearTimeout(this.timeout)

    const menuOpen = this.phoneButtonTarget.getAttribute('aria-expanded')

    if (menuOpen === 'true') {
      this._closePhoneMenu()
    } else {
      this._openPhoneMenu()
      this.timeout = setTimeout(this._closePhoneMenu.bind(this), 3000)
    }
  }

  /**
   * @function Controllers.HeaderController.handleBurlgerClick
   * @memberof Controllers
   * @description Toggles the mobile burger menu open and closed
   *
   * @param {Event} event - Touch event
   *
   * @return {undefined} Manipulates DOM
   *
   */
  handleBurglerClick(event) {
    event.preventDefault()

    this._toggleBurgler()
  }


  // Private


  /**
   * @function Controllers.HeaderController._toggleBurgler
   * @memberof Controllers
   * @description Toggles burger menu
   * @private
   *
   * @return {undefined} Manipulates DOM
   *
   */
  _toggleBurgler() {
    const menuOpen = this.burglerButtonTarget.getAttribute('aria-expanded')

    this.burglerButtonTarget.classList.toggle('active')
    this.burglerButtonTarget.setAttribute('aria-expanded', !(menuOpen === 'true'))
    this.burglerContainerTarget.classList.toggle('header__hamburgler--active')
    this.wrapperTarget.classList.toggle('header__wrapper--active')
    this.headerLinksTarget.classList.toggle('header__links--active')
  }

  /**
   * @function Controllers.HeaderController._openPhoneMenu
   * @memberof Controllers
   * @description Opens the mobile phone menu.
   * @private
   *
   * @param {Event} event - Touch event
   *
   * @return {undefined} Manipulates DOM
   *
   */
  _openPhoneMenu() {
    const phoneButton = this.phoneButtonTarget

    phoneButton.setAttribute('aria-expanded', true)
    phoneButton.classList.add('header__right-telephone--mobile--active')
    this.mobilePhoneSubMenuTarget.classList.add('header__phone-box--active')
  }

  /**
   * @function Controllers.HeaderController._closePhoneMenu
   * @memberof Controllers
   * @description Closes the mobile phone menu.
   * @private
   *
   * @param {Event} event - Touch event
   *
   * @return {undefined} Manipulates DOM
   *
   */
  _closePhoneMenu() {
    const phoneButton = this.phoneButtonTarget

    phoneButton.setAttribute('aria-expanded', false)
    phoneButton.classList.remove('header__right-telephone--mobile--active')
    this.mobilePhoneSubMenuTarget.classList.remove('header__phone-box--active')
  }
}
