import { Controller } from 'stimulus'

/**
 * Delays the loading of LiveChat until user interacts
 *
 * @class DelayLivechatController
 * @extends Controllers
 */
export default class extends Controller {
  static targets = ['external-js']

  /**
   * @function DelayLivechatController.connect
   * @description Called when Controller attaches
   */
  connect() {
    if (this._shouldLoad) {
      this.load()
      this._minimizeLiveChat()
    } else {
      this._showPlaceholder()
    }
  }

  /**
   * @function DelayLivechatController.load
   * @description Loads Livechat external javascript and initializes
   */
  load() {
    if (! this.hasTargetExternalJs) {
      this.element.innerHTML = ''
      this._attachExternalJs()
      this._initLiveChat()
      this._saveState()
    } else {
      this._initLiveChat()
    }
  }


  //
  // "Private" methods
  //

  /**
   * @return {boolean}
   * @private
   */
  get _livechatReady() {
    return typeof(window.LC_API) === 'object' &&
        typeof(window.LC_Invite) === 'object' &&
        typeof(window.LC_API.minimize_chat_window) === 'function'
  }

  /**
   * @description Creates script tag for external javascript
   * @private
   */
  _attachExternalJs() {
    const script = document.createElement('script')
    script.src = 'https://cdn.livechatinc.com/tracking.js'
    script.setAttribute('data-target', `${this.identifier}.external-js`)
    this.element.appendChild(script)
  }

  /**
   * @description Sets initial variables for Livechat
   * @private
   */
  _initLiveChat() {
    window.__lc = window.__lc || {}
    window.__lc.license = 7289151
    window.__lc_inited = undefined
  }

  /**
   * @description Ensure Livechat window is immediately minimized
   * @private
   */
  _minimizeLiveChat() {
    const tmp = setInterval( () => {
      if (this._livechatReady) {
        window.LC_API.minimize_chat_window()
        clearInterval(tmp)
      }
    }, 100)
  }

  /**
   * @description Sets sessionStorage variable
   * @private
   */
  _saveState() {
    sessionStorage.setItem(this._sessionKey, 'true')
  }

  /**
   * @description Key to use for sessionStorage
   * @return {string}
   * @private
   */
  get _sessionKey() {
    return this.identifier
  }

  /**
   * @description Has the user previously loaded this content?
   * @return {boolean}
   * @private
   */
  get _shouldLoad() {
    return sessionStorage.getItem(this._sessionKey) === 'true'
  }

  /**
   * @description Set parent element as visible
   * @private
   */
  _showPlaceholder() {
    this.element.style.opacity = 1
  }
}
