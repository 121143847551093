document.addEventListener('turbolinks:load', event => {
  const element = document.querySelector('[data-behavior*="capture-borrower-timezone-offset"]')

  if (element) {
    const timezoneField = element.id === 'borrower_timezone_offset' ? element : element.querySelector('#borrower_timezone_offset')

    if (timezoneField) {
      const localDate = new Date()
      timezoneField.value = localDate.getTimezoneOffset() * -60
    }
  }
}, { passive: true })
