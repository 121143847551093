import parseFloatString from 'helpers/parse-float-string'

/**
 * Format currency
 *
 * @param {number} value
 * @param {string} [prefix='$'] - Prefix character to utilize
 *
 * @return {string} formatted currency string
 */
export default function(value, prefix = '$') {
  const parsedValue = parseFloatString(value)
  const newValue = (isNaN(parsedValue) ? 0 : parsedValue).toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, "$1,") /* eslint "quotes": 0 */

  return (newValue === '0') ? '' : prefix + newValue
}
