import { Controller } from 'stimulus'

/**
 * Manages js for the clipboard-copy functionality (StimulusJS controller)
 *
 * @class ClipboardCopyController
 * @memberof Controllers
 *
 * @example
 * -# Example Markup (HAML)
 *
 *  %div{ data: { controller: 'clipboard-copy'} }
 *    %input.career__input{ value: 'copy-value', data: { target: 'clipboard-copy.copy' } }
 *    = link_to 'Copy', ..., data: { action: 'click->clipboard-copy#copy' }
 */
export default class extends Controller {
  static targets = [ 'copy' ]

  /**
   * @function Controllers.ClipboardCopyController.copy
   * @memberof Controllers
   * @description Copies selection from target to clipboard
   *
   * @param {Event} event - User initiated event
   *
   * @return {undefined}
   */
  copy(event) {
    event.preventDefault()

    if (navigator.userAgent.match(/ipad|iphone/i)) {
      this._rangeSelect()
      document.execCommand('copy')
    } else if (this.hasCopyTarget) {
      this.copyTarget.select()
      document.execCommand('copy')
    } else {
      this._fallback()
    }
  }

  /**
   * @function Controllers.ClipboardCopyController._rangeSelect
   * @memberof Controllers
   * @description Sets the start and end positions of the copy target
   * @return {undefined}
   */
  _rangeSelect() {
    const range = document.createRange()
    range.selectNodeContents(this.copyTarget)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    this.copyTarget.setSelectionRange(0, 999999)
  }

  /**
   * @function Controllers.ClipboardCopyController._fallback
   * @memberof Controllers
   * @description Copies element to clipboard. Handles the case where the user agent is non-iOS and a copy target has not been set
   * @return {undefined}
   */
  _fallback() {
    let copyValue = ''
    if (this.element.href !== undefined) {
      copyValue = this.element.href
    } else if (this.element.id !== undefined) {
      copyValue = this._relativeLink()
    } else if (this.element.value !== undefined) {
      copyValue = this.element.value
    }

    navigator.clipboard.writeText(copyValue)
  }

  /**
   * @function Controllers.ClipboardCopyController._relativeLink
   * @memberof Controllers
   * @description Appends the element's ID attribute to the url if it does not already contain it
   * @return {string}
   */
  _relativeLink() {
    let relativeLink = ''

    if (document.location.href.includes('#')) {
      relativeLink = document.location
    } else {
      relativeLink = document.location + '#' + this.element.id
    }

    return relativeLink
  }
}
