import { Controller } from 'stimulus'

/**
 * @class GalleryController
 * @memberof Controllers
 *
 * @example
 * -# Example Markup (HAML)
 * -# Note:
 * -#   * image-src value should use image_url helper
 * -#   * CSS classes abbreviated heavily for clarity/line length
 *
 * %div{ data: { controller: 'gallery', 'gallery-selected-class': 'image--selected' } }
 *   %div{ 'data-target': 'gallery.viewer' }
 *   .images
 *     .image{ data: { target: 'gallery.image', action: 'click->gallery#view', 'image-src': '1.png' } }
 *     .image{ data: { target: 'gallery.image', action: 'click->gallery#view', 'image-src': '2.png' } }
 *     .image.image--selected{ data: { target: 'gallery.image', action: 'click->gallery#view', 'image-src': '3.png' } }
 *     .image{ data: { target: 'gallery.image', action: 'click->gallery#view', 'image-src': '4.png' } }
 */
export default class extends Controller {
  static targets = [
    'image',
    'viewer'
  ]

  /**
   * @function Controllers.GalleryController.initialize
   * @memberof Controllers
   * @description Initialization
   */
  initialize() {
    this._applyImages()
  }

  /**
   * @function Controllers.GalleryController.view
   * @memberof Controllers
   * @description Sets viewer image to selected element image
   * @param {Event} event - click event from user
   */
  view(event) {
    const url = this.getImageUrlFor(event.currentTarget)

    this.imageTargets.forEach(el => el.classList.remove(this.selectedClass))
    event.currentTarget.classList.add(this.selectedClass)

    this.setImage(this.viewerTarget, url)
  }

  /**
   * @function Controllers.GalleryController._applyImages
   * @memberof Controllers
   * @description Sets viewer image backgrounds
   * @private
   */
  _applyImages() {
    this.imageTargets.forEach(image => {
      const url = this.getImageUrlFor(image)
      this.setImage(image, url)
    })

    this.viewer = this.currentImage
  }

  /**
   * @function Controllers.GalleryController.selectedClass
   * @memberof Controllers
   * @private
   * @return {string}
   */
  get selectedClass() {
    return this.data.get('selectedClass')
  }

  /**
   * @function Controllers.GalleryController.currentImage
   * @memberof Controllers
   * @private
   * @return {HTMLDivElementPrototype}
   */
  get currentImage() {
    return this.imageTargets.filter(el => el.classList.length > 1).pop()
  }

  /**
   * @function Controllers.GalleryController.viewer
   * @memberof Controllers
   * @param {Node} imageNode - Gallery Image Node to apply to viewer target
   */
  set viewer(imageNode) {
    const url = this.getImageUrlFor(imageNode)
    this.setImage(this.viewerTarget, url)
  }

  /**
   * @function Controllers.GalleryController.setImage
   * @memberof Controllers
   * @param {Node} node - Gallery Image Node to apply image url to
   * @param {string} url - Image url to apply to image node
   */
  setImage(node, url) {
    node.setAttribute('style', `background-image: url(${url});`)
  }

  /**
   * @function Controllers.GalleryController.getImageUrlFor
   * @memberof Controllers
   * @param {Node} node - Gallery Image Node to extract image url from
   * @return {string}
   */
  getImageUrlFor(node) {
    return node.dataset.imageSrc
  }
}

