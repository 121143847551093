import { Controller } from 'stimulus'

/**
 * @class CharacterCountController
 * @extends Controllers
 */
export default class extends Controller {
  static targets = []

  /**
   * @function CharacterCountController.connect
   * @description Invoked when controller connects to DOM
   */
  connect() {
    this.warnLength = Number.parseInt(this.element.dataset.warnLength)
    this.maxLength = Number.parseInt(this.element.dataset.maxLength)
    this.counterClass = this.element.dataset.counterCssClass
    this.counterWarnClass = `${this.counterClass}--warn`
    this.counterMaxClass = `${this.counterClass}--max`

    this.element.parentNode.classList.add(this.counterClass)

    this.update()
  }

  /**
   * @function CharacterCountController.update
   * @description Updates parent element's data attributes, used by CSS to display the current count
   */
  update() {
    const parent = this.element.parentNode
    const currentLength = this.element.value.length

    parent.dataset.characterCurrentLength = currentLength
    parent.dataset.characterMaxLength = this.maxLength

    parent.classList.remove(this.counterWarnClass)
    parent.classList.remove(this.counterMaxClass)

    if (currentLength >= this.maxLength) {
      parent.classList.add(this.counterMaxClass)
    } else if (currentLength >= this.warnLength) {
      parent.classList.add(this.counterWarnClass)
    }
  }
}

