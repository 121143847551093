import parseFloatString from 'helpers/parse-float-string'

/** @module formatCurrency */

/**
 * Takes an Integer or String formats it, potentially with a desired prefix
 *
 * @param {String} value to be formatted, can be String or Integer
 * @param {String} prefix to be prepended
 *
 * @return {String} with commas and maybe a prefix
 *
 * @example
 *
 * input = 3000, '$'
 *
 * output = '$3,000'
 */
export default function formatCurrency(value, prefix = '$') {
  const parsedValue = parseFloatString(value)
  let formattedValue

  if (isNaN(parsedValue)) {
    formattedValue =  prefix + '0'
  } else {
    formattedValue =  prefix + addCommasTo(parsedValue)
  }

  return formattedValue
}

/**
 * Takes an Integer, makes it a string and adds commas to it
 *
 * @param {Integer} value to be formatted
 *
 * @return {String} result - string with commas
 *
 * @example
 *
 * input = 3000
 *
 * output = '3,000'
 */
function addCommasTo(value) {
  return value.toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, '$1,')
}
