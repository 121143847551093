// Reveals password fields as clear text
document.addEventListener('turbolinks:load', function() {
  revealPassword()
})

/**
 * @private
 */
function revealPassword() {
  const passwordContainers = document.querySelectorAll('[data-behavior="reveal-password-toggle"]')

  if (passwordContainers) {
    for (let i = 0; i < passwordContainers.length; i++) {
      const checkboxField = passwordContainers[i].querySelector('input[type=checkbox]')
      const passwordField = passwordContainers[i].querySelector('input[type=password]')

      checkboxField.addEventListener('click', function() {
        passwordField.type = (checkboxField.checked) ? 'text' : 'password'
      })
    }
  }
}
