import { Application }            from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
const application = Application.start()
const context     = require.context('../controllers/main', true, /\.js$/)
application.load(definitionsFromContext(context))

document.addEventListener('turbolinks:before-cache', function() {
  application.controllers.forEach(function(controller) {
    if (typeof controller.teardown === 'function') {
      controller.teardown()
    }
  })
})

export default application
