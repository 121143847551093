import { InputMasking } from 'classes/input-masking'

/**
 * Replaces form DOM with results from ajax response
 * @example <caption>Example Usage</caption>
 *
 * = form_with model: article, data: { behavior: 'refresh-xhr-form' } do |f|
 *   -# ...
 *
 * @listens ajax:error
 */
document.addEventListener('ajax:error', event => {
  const element = event.target
  if (element.dataset.behavior && element.dataset.behavior.includes('refresh-xhr-form')) {
    element.innerHTML = event.detail[2].responseText

    InputMasking.applyAll()
    window.scrollTo(0, 0)
  }
}, true)
