// @todo: Replace with requestAnimationFrame (https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame)

document.addEventListener('click', event => {
  const element = event.target

  if (element.dataset.behavior && element.dataset.behavior.includes('scroll-to-top')) {
    let bodyElement
    if (document.documentElement.scrollTop > document.body.scrollTop) {
      bodyElement = document.documentElement
    } else {
      bodyElement = document.body
    }

    const interval = setInterval(duration => {
      const currentPosition = bodyElement.scrollTop
      duration -= 10

      if (currentPosition !== 0) {
        bodyElement.scrollTop = currentPosition - currentPosition / duration * 10
      } else {
        clearInterval(interval)
      }
    }, 10, 100)

    document.addEventListener('touchstart', () => {
      clearInterval(interval)
    })
  }
}, { passive: true })
