export default {
  SCROLL_POSITION:  'scroll-position',
  PAGE_INVALIDATED: 'page-invalidated',

  patch(Turbolinks) {
    this._setManualBrowserScrollRestoration()
    Turbolinks.BrowserAdapter.prototype.reload = this._patchedReload.bind(this)
    addEventListener('beforeunload', this._persistScrollPosition.bind(this))
    addEventListener('DOMContentLoaded', this._maybeScrollOnLoad.bind(this))
  },

  _setManualBrowserScrollRestoration() {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
  },

  _patchedReload() {
    sessionStorage.setItem(this.PAGE_INVALIDATED, 'true')
    location.reload()
  },

  _persistScrollPosition() {
    sessionStorage.setItem(
      this.SCROLL_POSITION,
      JSON.stringify({
        scrollX:  scrollX,
        scrollY:  scrollY,
        location: location.href
      })
    )
  },

  _maybeScrollOnLoad(event) {
    const scrollPosition = JSON.parse(sessionStorage.getItem(this.SCROLL_POSITION))

    if (this._shouldScroll(scrollPosition)) {
      scrollTo(scrollPosition.scrollX, scrollPosition.scrollY)
    }

    sessionStorage.removeItem(this.SCROLL_POSITION)
    sessionStorage.removeItem(this.PAGE_INVALIDATED)
  },

  _shouldScroll(scrollPosition) {
    return (
      scrollPosition &&
      scrollPosition.location === location.href &&
      !JSON.parse(sessionStorage.getItem(this.PAGE_INVALIDATED))
    )
  }
}
