import { Controller } from 'stimulus'

/**
 * @class CareerController
 * @extends Controllers
 */
export default class extends Controller {
  static targets = [
    'careerRowTemplate',
    'careersJson',
    'careerTable',
    'careerTableRow',
    'filterButton',
    'keyword',
    'noCareers',
    'search',
    'showButton',
    'specialty'
  ]

  /**
   * @function CareerController.connect
   * @description Called when Controller attaches
   */
  connect() {
    this.careers = []
    this._loadCareers()
    this.filter()
  }

  /**
   * @description Has a keyword been entered?
   * @return {string}
   */
  get keyword() {
    const currentValue = this.keywordTarget.value
    return currentValue !== undefined ? currentValue.toLowerCase() : ''
  }

  /**
   * @description Has a location been entered?
   * @return {string}
   */
  get location() {
    const currentLocation = this.filterButtonTargets.filter(el => el.classList.length > 2).pop()
    return currentLocation.dataset.location
  }

  /**
   * @description Has a specialty been entered?
   * @return {string}
   */
  get specialty() {
    return this.specialtyTarget.value
  }

  /**
   * @function CareerController.setLocation
   * @description Set the focus to the correct location button
   * @param {event} event - Event from click action
   */
  setLocation(event) {
    const cssClass = event.currentTarget.dataset.selectedClass
    this.filterButtonTargets.forEach(el => el.classList.remove(cssClass))
    event.currentTarget.classList.add(cssClass)
  }

  /**
   * @function CareerController.filter
   * @description Initiate the _filterCareers() function
   * @param {event} event - Event from click action
   */
  filter(event) {
    this._filterCareers()
  }

  /**
  * @function CareerController.sortBySpecialty
  * @description Sorts careers by the category/specialty
  */
  sortBySpecialty() {
    const orderedCareers = [ ...this.careers ].reverse().sort((a, b) => {
      return (a.attributes.category > b.attributes.category) ? 1 : -1
    })

    this._filterCareers(orderedCareers)
  }

  /**
  * @function CareerController.showFilters
  * @description Hides 'Show filters' button and displays filter buttons
  * @param {event} event - Event from click action
  */
  showFilters(event) {
    event.currentTarget.remove()

    const showElement = this.searchTarget
    const toggleShowClass = showElement.dataset.toggleShowClass
    showElement.classList.toggle(toggleShowClass)
  }

  /**
  * @function CareerController._loadCareers
  * @description Load careers from
  */
  _loadCareers() {
    const json = JSON.parse(this.careersJsonTarget.innerHTML)
    json.data.forEach(career => this.careers.push(career))
  }

  /**
  * @function CareerController._filterCareers
  * @description Filters careers and sends them to be displayed
  * @param {Object} careers - Array of careers
  */
  _filterCareers(careers = this.careers) {
    const careersToDisplay = careers.filter(career =>
      this._isMatchingLocation(career) &&
      this._keywordPresent(career) &&
      this._isOfSpecialty(career)
    )

    this._displayCareers(careersToDisplay)
  }

  /**
   * @function CareerController._displayCareers
   * @description Displays careers
   * @param {Object} careersToDisplay - Array of careers
   */
  _displayCareers(careersToDisplay) {
    this._removeAllCareers()

    careersToDisplay.forEach(career => this._displayCareer(career))

    if (!careersToDisplay.length) {
      this._displayNoCareers()
    }
  }

  /**
  * @function CareerController._displayCareer
  * @description Displays each career in a row
  * @param {Object} careerHash - Object with attributes of a career
  */
  _displayCareer(careerHash) {
    const career = careerHash.attributes
    const row = this.careerRowTemplateTarget.content.cloneNode(true)

    row.querySelector('a').href = career['local_link']
    row.querySelector('div[name^=title]').innerHTML = career['title']
    row.querySelector('div[name^=specialty').innerHTML = career['category']
    row.querySelector('div[name^=location]').innerHTML = career['location']

    this.careerTableTarget.appendChild(row)
  }

  /**
  * @function CareerController._displayNoCareers
  * @description Displays copy stating that there are no careers to display
  */
  _displayNoCareers() {
    const parent = this.careerTableTarget
    const template = this.noCareersTarget.content.cloneNode(true)
    parent.appendChild(template)
  }

  /**
  * @function CareerController._removeAllCareers
  * @description Removes all careers from the table
  */
  _removeAllCareers() {
    this.careerTableRowTargets.forEach(row => row.remove())
  }

  /**
  * @function CareerController._isRemote
  * @description Checks whether the word remote appears in the title
  * @param {Object} career - Object with attributes of a career
  * @return {boolean}
  */
  _isRemote(career) {
    return career.attributes.title.toLowerCase().includes('remote')
  }

  /**
  * @function CareerController._isMatchingLocation
  * @description Checks whether the location matches the filter
  * @param {object} career - The career object
  * @return {boolean}
  */
  _isMatchingLocation(career) {
    switch (this.location) {
    case 'remote':
      return this._isRemote(career)
    case 'denver':
      return !this._isRemote(career)
    default:
      return true
    }
  }

  /**
  * @function CareerController._keywordPresent
  * @description Checks whether the keyword is present in the career
  * @param {Object} career - Object of a career with attributes
  * @return {boolean}
  */
  _keywordPresent(career) {
    return Object.values(career.attributes).join(' ').toLowerCase().includes(this.keyword)
  }

  /**
  * @function CareerController._isOfSpecialty
  * @description Checks whether the career has filtered specialty
  * @param {Object} career - Object of a career with attributes
  * @return {boolean}
  */
  _isOfSpecialty(career) {
    return this.specialty ? career.attributes.category === this.specialty : true
  }
}
