import addEventListenersOn from 'helpers/add-event-listeners-on'

/**
 * This behavior allows you to toggle a css class on an element after a set period
 * of user inactivity
 *
 * This behavior expects a number of data attributes:
 *   behavior         : 'toggle-class-on-inactivity'
 *   inactivity-class : string, css class to toggle once the delay has expired
 *   inactivity-delay : integer, number of seconds to wait for user click
 *
 * _Note_: `scroll` event gets fired on page load if the user has already been on the page, scrolled down at any length
 * navigate away or refresh, and come back to the page. This results in no toggling of classes from this behavior
 *
 */
document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('[data-behavior*="toggle-class-on-inactivity"]')

  for (let i = 0, len = elements.length; i < len; i++) {
    const element = elements[i]
    const delay = element.dataset.inactivityDelay
    const classToToggle = element.dataset.inactivityClass

    const inactivityTimer = setTimeout(() => {
      element.classList.toggle(classToToggle)
    }, delay * 1000)

    /**
     * @todo Look into refactoring 'this'
     */
    /* eslint "no-invalid-this": "off" */
    const clearTimer = function() {
      this['element'].classList.add(this['classToToggle'])
      clearTimeout(this['inactivityTimer'])
    }

    const addToggleClass = {
      classToToggle:   classToToggle,
      element:         element,
      inactivityTimer: inactivityTimer
    }

    addEventListenersOn(document, 'click keydown touchstart', clearTimer.bind(addToggleClass))
    window.addEventListener('scroll', clearTimer.bind(addToggleClass))
  }
}, { passive: true })
