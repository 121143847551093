/*
 * Collection expand acts to reveal a number of hidden items in a collection.
 *
 * This behavior expects a number of data attributes:
 *   behavior : 'collection-expand'
 *   'collection-expand-toggle-class' : '<class to be toggled>'
 *   'collection-expand-target': '<the behavior of the items to be toggled>'
 *
 *   i.e.
 *     behavior                        : 'collection-expand'
 *     'collection-expand-toggle-class': 'programs__card--no-display'
 *     'collection-expand-target'      : 'appear'
 *
 * This behavior also expects one or more items with the data attribute
 * corresponding to the 'collection-expand-target' like:
 *
 *   behavior : '<behavior of item to be toggled>'
 *
 *   i.e.
 *     behavior: 'appear'
 *
 * The toggle class could provide a simple 'display: none' declaration and would
 * be the the mechanism for showing/hiding an element involved.
 *
 * This is a one-time irreversible event (unless the page is reloaded) which
 * adds the toggle class to the item clicked, causing it to disappear, and
 * removes the same class from elements that have the behavior of the
 * 'collection-expand-target', causing them to appear.
 *
*/
const behavior = 'collection-expand'

document.addEventListener('click', event => {
  const element = event.target

  if (element.dataset.behavior && element.dataset.behavior.includes(behavior)) {
    const toggleClass = element.dataset.collectionExpandToggleClass
    const targetBehavior = element.dataset.collectionExpandTarget
    const elementsToShow = Array.from(document.querySelectorAll(`[data-behavior*="${targetBehavior}"]`))

    element.classList.toggle(toggleClass)

    let element
    for (element of elementsToShow) {
      element.classList.toggle(toggleClass)
    }
  }
}, { passive: true })

