import formatCurrency from 'helpers/format-currency'

document.addEventListener('turbolinks:load', function() {
  const priceSliders = document.querySelectorAll('[data-behavior="price-slider"]')

  if (!priceSliders) {
    return priceSliders
  }

  Array.from(priceSliders).forEach(function(priceSlider) {
    const sliderFormattedResults = priceSlider.parentElement.querySelector('[data-behavior="slider-formatted-results"]')
    const sliderResults = priceSlider.parentElement.querySelector('[data-behavior="slider-results"]')
    const defaultRangeSliderValue = priceSlider.value

    sliderFormattedResults.innerText = formatCurrency(defaultRangeSliderValue)
    priceSlider.value = defaultRangeSliderValue

    priceSlider.addEventListener('input', updatePriceValue)
    priceSlider.addEventListener('change', updatePriceValue)

    /**
     * @param {event} event
     * @private
     */
    function updatePriceValue(event) {
      if (sliderFormattedResults && event.target.value) {
        sliderFormattedResults.innerText = formatCurrency(event.target.value)
      }

      if (sliderResults && event.target.value) {
        sliderResults.value = event.target.value
      }
    }
  })
})

