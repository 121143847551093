import { Controller } from 'stimulus'

/**
 * Manages js for the mortgage-type-comparison page (StimulusJS controller)
 *
 * @class MortgageTypeComparisonController
 * @memberof Controllers
 */
export default class extends Controller {
  static targets = [ 'wrapper', 'table', 'arrow', 'tableContainer', 'resetButton', 'filterButton', 'column' ]

  /**
   * @function Controllers.MortgageTypeComparisonController.connnect
   * @memberof Controllers
   * @description Executes on controller connection
   */
  connect() {
    this.filterButtonTarget.setAttribute('disabled', true)
  }

  /**
   * @function Controllers.MortgageTypeComparisonController.scroll
   * @memberof Controllers
   * @description Keeps tabs on the extent of the users scroll and removes the fuzzy white that implies scrollability
   *
   * @param {Event} event
   *
   * @return {undefined} manipulates DOM
   */
  scroll(event) {
    const tableWidth     = this.wrapperTarget.clientWidth
    const tableViewport  = this.tableTarget.clientWidth
    const scrollEnd      = tableWidth - tableViewport
    const extentScrolled = event.target.scrollLeft

    if (extentScrolled > 0) {
      this.arrowTarget.classList.add('mortgage-type-comparison__table-scroll-right-arrow--hide')
    }

    if (extentScrolled >= scrollEnd) {
      this.tableTarget.classList.add('mortgage-type-comparison__table--end')
    } else {
      this.tableTarget.classList.remove('mortgage-type-comparison__table--end')
    }
  }

  /**
   * @function Controllers.MortgageTypeComparisonController.reset
   * @memberof Controllers
   * @description Resets the table to unfiltered initial state, nothing checked, filter button disabled.
   *
   * @param {Event} event
   *
   * @return {undefined} manipulates DOM
   */
  reset(event) {
    this.element.classList.remove('mortgage-type-comparison--filtered')

    const columns = this.columnTargets
    columns.forEach(column => {
      column.getElementsByTagName('input')[0].checked = false
      column.classList.remove('mortgage-type-comparison__table-column--hide')
    })

    this.checkedCount = 0
    this.resetButtonTarget.blur()
    this.filterButtonTarget.setAttribute('disabled', true)
  }

  /**
   * @function Controllers.MortgageTypeComparisonController.filter
   * @memberof Controllers
   * @description Removes the unchecked loan options for side-by-side comparison of the loans the user has checked
   *
   * @param {Event} event
   *
   * @return {undefined} manipulates DOM
   */
  filter(event) {
    this.tableContainerTarget.scrollTo(0, 0)
    this.element.classList.add('mortgage-type-comparison--filtered')

    const columns = this.columnTargets
    columns.forEach(column => {
      if (!column.getElementsByTagName('input')[0].checked) {
        column.classList.add('mortgage-type-comparison__table-column--hide')
      }
    })

    this.filterButtonTarget.setAttribute('disabled', true)
  }

  /**
   * @function Controllers.MortgageTypeComparisonController.maybeAllowFilter
   * @memberof Controllers
   * @description Assertains whether the filter button should be activated.
   *
   * @param {Event} event
   *
   * @return {undefined} manipulates DOM
   */
  maybeAllowFilter(event) {
    this._calculateCheckedCount(event.target.checked)

    if (this.checkedCount >= 2 && this.checkedCount < this.columnTargets.length) {
      this.filterButtonTarget.removeAttribute('disabled')
    } else {
      this.filterButtonTarget.setAttribute('disabled', true)
    }
  }

  /**
   * @function Controllers.MortgageTypeComparisonController._calculateCheckedCount
   * @memberof Controllers
   * @description Increments or decrements the count of checked boxes in state.
   *
   * @param {Boolean} checked - Increments on true, decrements on false
   *
   * @return {undefined} manipulates DOM
   */
  _calculateCheckedCount(checked) {
    if (checked) {
      this.checkedCount++
    } else {
      this.checkedCount--
    }
  }

  /**
   * @function Controllers.MortgageTypeComparisonController.checkedCount
   * @memberof Controllers
   * @description Getter method for checkedCount, grabs the data string and gives an Int.
   *
   * @return {Integer} the number of checked boxes
   */
  get checkedCount() {
    return parseInt(this.data.get('checked-count'))
  }

  /**
   * @function Controllers.MortgageTypeComparisonController.checkedCount
   * @memberof Controllers
   * @description Setter method for the checkedCount data attribute
   *
   * @param {Integer} value - the potentially manipulated value
   *
   * @return {undefined} manipualtes DOM
   */
  set checkedCount(value) {
    this.data.set('checked-count', value)
  }
}
