/*
 * This portion of js acts to expand hidden content. Please see the reviews page
 * and the faq pages for examples in action.
 *
 * This behavior expects a number of data attributes:
 *   behavior                      : 'content-expand'
 *   'content-expand-toggle-class' : '<class to be added>'
 *   'content-expand-toggle-button': '<target for button>'*
 *
 * The third data-attribute is optional. If it isn't included, the element upon
 * which the behavior attribute is applied becomes the object of the click
 * listener. If it is included, the element which is a descedant (specified by
 * the value of 'content-expand-toggle-button') becomes the object of the click
 * listener. This effect is acheived by defaulting to the element itself in the
 * assignment of 'const button'.
 *
 * In either case, the 'expanded' class is added to the element with the
 * behavior attribute.
 *
 * NOTE:
 *   If you have links that exist within the toggle text area you'll need to
 *   add the following CSS to make the link visible to click.
 *   `pointer-events: visible;`
*/
document.addEventListener('turbolinks:load', event => {
  const elements = Array.from(document.querySelectorAll('[data-behavior*="content-expand"]'))

  for (const element of elements) {
    const toggleButtonQuery = element.dataset.contentExpandToggleButton
    const button            = element.querySelector(toggleButtonQuery) || element

    button.addEventListener('click', event => {
      const toggleClass = element.dataset.contentExpandToggleClass
      element.classList.toggle(toggleClass)
    }, { passive: true })
  }
}, { passive: true })
