/** @module addEventListenersOn */

/**
 * Add Event Listeners On
 *
 * @param {Object} el - Element
 * @param {string} s - Event descriptor?
 * @param {Function} fn - Function
 *
 * @example
 * import addEventListenersOn from 'helpers/add-event-listeners-on'
 */
export default function(el, s, fn) {
  s.split(' ').forEach(e => el.addEventListener(e, fn, false))
}
