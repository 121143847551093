import { Controller } from 'stimulus'


/**
 * Manages js for the form progress bar (StimulusJS controller)
 *
 * @class FormProgressBarController
 * @memberof Controllers
 *
 * @example
 * -# Example Markup (HAML)
 *
 * %span{'data-controller' => 'modal'}
 *   = link_to 'Open Modal', '#', 'data-action' => 'modal#show'
 *
 *   .modal{'data-target' => 'modal.content'}
 *     .modal__dialog
 *       anything you want here
 *       = render 'shared/lead_form' # for instance
 *
 * -# Example with YouTube video (HAML)
 * -# Note: iframe id and src 'enablejsonapi' are required
 * -# See: https://developers.google.com/youtube/iframe_api_reference
 *
 *  %span{ 'data-controller': 'modal' }
 *    = link_to 'Open', nil, 'data-action': 'modal#show'
 *    .modal{ 'data.target': 'modal.content', index: '1', 'yt-video': 'iframe-id-here' }
 *      %iframe#iframe-id-here{ src: 'https://youtube.com/embed/ID?enablejsonapi=1' }
 */
export default class extends Controller {
  static targets = [ 'content' ]

  /**
   * @function Controllers.ModalController.initialize
   * @memberof Controllers
   * @description Defines callbacks for event listener usage
   */
  initialize() {
    this.index = this.data.get('index')
    this.qualifier = this.index === undefined ? '' : ':' + this.index

    this.modalShowEventCallback = () => this._showModal()
    this.modalHideEventCallback = () => this._hideModal()

    this._addExitButton()
    this._addClickToCloseOn(this.contentTarget)
    this.bound_keydown = this._keydown.bind(this)
    this._ytMaybeInit()
  }

  /**
   * @function Controllers.ModalController.connect
   * @memberof Controllers
   * @description Adds event listeners
   */
  connect() {
    window.addEventListener(this.showEventId, this.modalShowEventCallback)
    window.addEventListener(this.hideEventId, this.modalHideEventCallback)
  }

  /**
   * @function Controllers.ModalController.teardown
   * @memberof Controllers
   * @description Removes event listener from document on teardown.
   */
  teardown() {
    window.removeEventListener(this.showEventId, this.modalShowEventCallback)
    window.removeEventListener(this.hideEventId, this.modalHideEventCallback)
  }

  /**
   * @function Controllers.ModalController.hide
   * @memberof Controllers
   * @description Closes modal through event dispatch
   * @param {Event} event from user
   */
  hide(event) {
    // prevent click bubbling
    if (event !== undefined && event.target !== event.currentTarget) {
      return
    }

    if (event !== undefined) {
      event.preventDefault()
    }

    window.dispatchEvent(new CustomEvent(this.hideEventId))
  }

  /**
   * @function Controllers.ModalController.show
   * @memberof Controllers
   * @description Shows modal through event dispatch
   * @param {Event} event from user
   */
  show(event) {
    event.preventDefault()

    window.dispatchEvent(new CustomEvent(this.showEventId))
  }

  //
  // "Private" methods
  //

  /**
   * @function Controllers.ModalController.hideModal
   * @memberof Controllers
   * @description Triggered by an event on the window, this function does the
   * actual hiding of the modal
   * @param {Event} event from user
   */
  _hideModal() {
    this._detachKeydownListener()
    this.contentTarget.classList.remove('modal--open')
    document.body.classList.remove('body--no-scroll')

    if (this._hasYTVideo && this.player) {
      this.player.PlayerState = window.YT.PlayerState.PAUSED
    }
  }

  /**
   * @function Controllers.ModalController._showModal
   * @memberof Controllers
   * @description Triggered by an event on the window, this function does the
   * actual showing of the modal
   * @param {Event} event from user
   */
  _showModal() {
    this._attachKeydownListener()
    this.contentTarget.classList.add('modal--open')
    document.body.classList.add('body--no-scroll')

    if (this._hasYTVideo && !this.player) {
      this.player = new window.YT.Player(this._ytId, {
        videoId: this._ytId, events: { 'onStateChange': () => this.onPlayerStateChange() }
      })
    }
  }

  /**
   * @function Controllers.ModalController.onPlayerStateChange
   * @memberof Controllers
   * @description returns focus from the YouTube player to the window to allow escape functionality
   * @param {Event} event from user
   */
  onPlayerStateChange(event) {
    window.focus()
  }

  /**
   * @function Controllers.ModalController._addClickToCloseOn
   * @memberof Controllers
   * @description Adds close action to element
   * @private
   *
   * @param {object} element
   */
  _addClickToCloseOn(element) {
    element.dataset.action = 'click->modal#hide'
  }

  /**
   * @function Controllers.ModalController._attachKeydownListener
   * @memberof Controllers
   * @description Adds keydown listener to window
   * @private
   */
  _attachKeydownListener() {
    window.addEventListener('keydown', this.bound_keydown, false)
  }

  /**
   * @function Controllers.ModalController._detachKeydownListener
   * @memberof Controllers
   * @description Removes keydown listener to window
   * @private
   */
  _detachKeydownListener() {
    window.removeEventListener('keydown', this.bound_keydown, false)
  }

  /**
   * @function Controllers.ModalController._keydown
   * @memberof Controllers
   * @description Closes modal on escape keydown
   * @param {Event} event from user
   * @private
   */
  _keydown(event) {
    if (event.key === 'Escape') {
      this.hide()
    }
  }

  /**
   * @function Controllers.ModalController._addExitButton
   * @memberof Controllers
   * @description Adds exit button to modal
   * @private
   */
  _addExitButton() {
    const button = document.createElement('span')
    button.classList.add('modal__exit')
    button.innerHTML = 'Close'
    this._addClickToCloseOn(button)

    this.contentTarget.appendChild(button)
  }

  /**
   * @function Controllers.ModalController._ytMaybeInit
   * @memberof Controllers
   * @description Injects YouTube iframe API
   * @private
   */
  _ytMaybeInit() {
    if (!this._hasYTVideo || window['YT']) { return }

    const tag = document.createElement('script')
    tag.id = 'youtube-js-api'
    tag.src = 'https://www.youtube.com/iframe_api'
    this.contentTarget.appendChild(tag)
  }

  /**
   * @function Controllers.ModalController._ytId
   * @memberof Controllers
   * @private
   * @return {string}
   */
  get _ytId() {
    return this.contentTarget.dataset.ytVideo
  }

  /**
   * @function Controllers.ModalController._hasYTVideo
   * @memberof Controllers
   * @private
   * @return {boolean}
   */
  get _hasYTVideo() {
    return this._ytId !== undefined
  }

  /**
   * @function Controllers.ModalController.showEventId
   * @memberof Controllers
   * @private
   * @return {string}
   */
  get showEventId() {
    return `modal:show${this.qualifier}`
  }

  /**
   * @function Controllers.ModalController.hideEventId
   * @memberof Controllers
   * @private
   * @return {string}
   */
  get hideEventId() {
    return `modal:hide${this.qualifier}`
  }
}
