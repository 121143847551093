/**
 * @param {Event} event - User initiated keydown event
 * @private
 */
function handleFirstTab(event) {
  if (event.keyCode === 9) {
    document.body.classList.add('user-is-tabbing')
    window.removeEventListener('keydown', handleFirstTab)
  }
}

window.addEventListener('keydown', handleFirstTab)
