import { Controller } from 'stimulus'

/**
 * @class HudListingsController
 * @extends Controllers
 */
export default class extends Controller {
  static targets = [
    'city',
    'state',
    'lang',
    'table',
    'row'
  ]

  /** Initialize HudListings form */
  initialize() {
    this.noState = ''
    this.noLanguage = ''

    if (this.cityTarget.value !== '' || this.langTarget.value !== this.noLanguage) {
      this.filterRows()
    }

    if (this.stateTarget.value !== this.noState) {
      this.filterStates()
    }
  }

  /** Filter HUD Listing states */
  filterStates() {
    const filterState = this.stateTarget.value

    this.tableTargets.forEach(table => {
      table.classList.add('hud-table__section--hide')

      if (filterState === this.noState || table.dataset.hudListingsState === filterState) {
        table.classList.remove('hud-table__section--hide')
      }
    })

    this.hideEmptyTables()
  }

  /** Filter HUD Listing rows */
  filterRows() {
    const lang = this.langTarget.value
    const city = this.cityTarget.value

    this.rowTargets.forEach(row => {
      if (
        (city === '' || row.dataset.hudListingsAddress.includes(city.toUpperCase())) &&
        (lang === this.noLanguage || row.dataset.hudListingsLanguage.includes(lang))
      ) {
        row.classList.remove('hud-table__row--hide')
      } else {
        row.classList.add('hud-table__row--hide')
      }
    })

    this.hideEmptyTables()
  }

  /** Hide empty tables  */
  hideEmptyTables() {
    this.tableTargets.forEach(table => {
      const totalRows = table.getElementsByClassName('hud-table__row').length
      const hiddenRows = table.getElementsByClassName('hud-table__row--hide').length
      const tableState = table.dataset.hudListingsState
      const filterState = this.stateTarget.value

      if (totalRows === hiddenRows) {
        table.classList.add('hud-table__section--hide')
      } else if (filterState === this.noState || tableState === filterState) {
        table.classList.remove('hud-table__section--hide')
      }
    })
  }

  /** Reset data */
  resetData() {
    this.cityTarget.value = ''
    this.stateTarget.value = 'All States'
    this.langTarget.value = 'All Languages'
    this.filterStates()
    this.filterRows()
  }
}
