const behavior = 'character-count'

document.addEventListener('turbolinks:load', () => {
  const elements = Array.from(document.querySelectorAll(`[data-behavior~=${behavior}]`))

  elements.forEach(element => {
    charCount(element)
  })
}, true)

document.addEventListener('input', event => {
  const element = event.target

  if (element.dataset.behavior && element.dataset.behavior.includes(behavior)) {
    charCount(element)
  }
}, true)

/**
 * @param {object} element
 * @private
 */
function charCount(element) {
  const parent        = element.parentNode
  const countingClass = element.dataset.characterCountCssClass
  const currentLength = element.value.length
  const warnLength    = Number.parseInt(element.dataset.characterWarnLength)
  const maxLength     = Number.parseInt(element.dataset.characterMaxLength)

  updateCounter(parent, countingClass, currentLength, warnLength, maxLength)
}

/**
 * @param {object} parent
 * @param {string} countingClass
 * @param {number} currentLength
 * @param {number} warnLength
 * @param {number} maxLength
 * @private
 */
function updateCounter(parent, countingClass, currentLength, warnLength, maxLength) {
  parent.classList.add(countingClass)

  setParentCharacterLengths(parent, currentLength, maxLength)
  setParentCharacterClass(parent, countingClass, currentLength, warnLength, maxLength)
}

/**
 * @param {object} parent
 * @param {string} countingClass
 * @param {number} currentLength
 * @param {number} warnLength
 * @param {number} maxLength
 * @private
 */
function setParentCharacterClass(parent, countingClass, currentLength, warnLength, maxLength) {
  const warnClass = `${countingClass}--warn`
  const maxClass  = `${countingClass}--max`

  parent.classList.remove(warnClass)
  parent.classList.remove(maxClass)

  if (currentLength >= maxLength) {
    parent.classList.add(maxClass)
  } else if (currentLength >= warnLength) {
    parent.classList.add(warnClass)
  }
}

/**
 * @param {object} parent
 * @param {number} currentLength
 * @param {number} maxLength
 * @private
 */
function setParentCharacterLengths(parent, currentLength, maxLength) {
  parent.setAttribute('data-character-current-length', currentLength)
  parent.setAttribute('data-character-max-length', maxLength)
}
