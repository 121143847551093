document.addEventListener('click', event => {
  const element = event.target

  if (element.dataset.behavior && element.dataset.behavior.includes('scroll-y-to')) {
    const menuHeight = 100
    const scrollToElement = document.querySelector('[data-scroll-on="' + element.dataset.scrollTo + '"]')

    window.scrollBy({ top: scrollToElement.getBoundingClientRect().top - menuHeight, left: 0, behavior: 'smooth' })
  }
}, { passive: true })
