import { Controller } from 'stimulus'

/**
 * Manages js for front-end validated fields (StimulusJS controller)
 *
 * @class RequiredFieldController
 * @memberof Controllers
 *
 * @example
 *
 * -# Example Markup (HAML)
 *
 *    On a the input in question:
 *
 *      .field{ 'data-target': 'required-field.fieldContainer' }
 *        = label_tag :s1, do
 *          = phone_field_tag :s1, ... data: { 'required-field-rule': 'ssn' }
 *
 */
export default class extends Controller {
  static targets = [
    'fieldContainer'
  ]

  /**
   * @function Controllers.RequiredFieldController.initialize
   * @memberof Controllers
   * @description Sets class variables to be used by functions.
   *
   */
  initialize() {
    this.fieldErrorCssClass = {
      'text':       'form__text-field--error',
      'email':      'form__text-field--error',
      'tel':        'form__text-field--error',
      'select-one': 'form__select--error',
      'textarea':   'form__textarea-field--error',
      'radio':      'form__radio-field--error'
    }
    this.errorListHideCssClass = 'form__errors-list--hidden'
    this.onSubmitEventCallback = event => { this.maybeSubmit(event) }
  }

  /**
   * @function RequiredFieldController.connect
   * @description Called when Controller attaches
   */
  connect() {
    this.element.addEventListener('submit', this.onSubmitEventCallback)
  }

  /**
   * @function RequiredFieldController.teardown
   * @description Called when Controller detaches
   */
  teardown() {
    this.element.removeEventListener('submit', this.onSubmitEventCallback)
  }

  /**
   * @function Controllers.RequiredFieldController.validate
   * @memberof Controllers
   * @description Called by the Purchase or Refianance controllers, rolls
   * through required fields updating their UI and getting an overall validity.
   *
   * @return {Boolean} allValid - Boolean representing universal validity
   *
   */
  validate() {
    const allValid = this.fieldContainerTargets.map(fieldContainer => {
      return this._check(fieldContainer)
    })

    return allValid.every(value => value === true)
  }

  /**
   * @function Controllers.RequiredFieldController.maybeSubmit
   * @memberof Controllers
   * @description Called directly from views to prevent click action if missing an input.
   *
   *@param {Event} event - User initiated on submit click
   */
  maybeSubmit(event) {
    if (!this.validate()) {
      event.preventDefault()
    }
  }

  /**
   * @function Controllers.RequiredFieldController._check
   * @memberof Controllers
   * @description Gets the validity of a single field and updates its UI
   * @private
   *
   * @param {Element} fieldContainer - the DOM element containing the required
   * field
   *
   * @return {Boolean} isFieldValid - Boolean representing field validity
   *
   */
  _check(fieldContainer) {
    const field       = this._determineField(fieldContainer)
    const errorList   = fieldContainer.getElementsByTagName('ul').item(0)
    const validations = this._rulesOn(field)
    const optional    = field.dataset.requiredFieldOptional

    if (this._earlyBailConditions(field, optional)) { return true }

    let isFieldValid = true

    validations.forEach(rule => {
      switch (rule) {
        case 'presence':
          isFieldValid = isFieldValid && this._presenceRule(field)
          break
        case 'money':
          isFieldValid = isFieldValid && this._moneyRule(field)
          break
        case 'formatFullDate':
          isFieldValid = isFieldValid && this._formatFullDateRule(field)
          break
        case 'formatMonthYearDate':
          isFieldValid = isFieldValid && this._formatMonthYearDateRule(field)
          break
        case 'ssn':
          isFieldValid = isFieldValid && this._ssnRule(field)
          break
        case 'short-ssn':
          isFieldValid = isFieldValid && this._shortSsnRule(field)
          break
        case 'email':
          isFieldValid = isFieldValid && this._emailRule(field)
          break
        case 'phone':
          isFieldValid = isFieldValid && this._phoneRule(field)
          break
        case 'zip':
          isFieldValid = isFieldValid && this._zipRule(field)
      }
    })

    this._updateStylingOn(field, errorList, isFieldValid)

    return isFieldValid
  }

  /**
   * @function Controllers.RequiredFieldController._determineField
   * @memberof Controllers
   * @description returns the appropriate field target
   * @private
   *
   * @param {Element} fieldContainer - the DOM element containing the fields to
   * be validated
   *
   * @return {Element} the DOM element to be manipulated
   *
   */
  _determineField(fieldContainer) {
    if (fieldContainer.dataset.radio == 'true') {
      return fieldContainer
    } else {
      return fieldContainer.getElementsByTagName('input').item(0) ||
      fieldContainer.getElementsByTagName('select').item(0) ||
      fieldContainer.getElementsByTagName('textarea').item(0)
    }
  }

  /**
   * @function Controllers.RequiredFieldController._earlyBailConditions
   * @memberof Controllers
   * @description Updates the styling for a field
   * @private
   *
   * @param {Element} field - the dom node being validated
   * @param {String} optional - string value of 'true' or undefined
   *
   * @return {Boolean} value representing whether to bail or not
   *
   */
  _earlyBailConditions(field, optional) {
    if (optional === 'true' && field.value === '') {
      return true
    } else if (field.offsetParent === null) {
      return true
    } else {
      return false
    }
  }

  /**
   * @function Controllers.RequiredFieldController._updateStylingOn
   * @memberof Controllers
   * @description Updates the styling for a field
   * @private
   *
   * @param {Element} field - the dom node being validated
   * @param {Element} errorList - the dom node error list
   * @param {Boolean} status - a boolean representing field validity
   *
   * @return {undefined} manipulates DOM
   *
   */
  _updateStylingOn(field, errorList, status) {
    const fieldKey = field.type || 'radio'

    if (status) {
      field.classList.remove(this.fieldErrorCssClass[fieldKey])
      if (errorList) errorList.classList.add(this.errorListHideCssClass)
    } else {
      field.classList.add(this.fieldErrorCssClass[fieldKey])
      if (errorList) errorList.classList.remove(this.errorListHideCssClass)
    }
  }

  /**
   * @function Controllers.RequiredFieldController._rulesOn
   * @memberof Controllers
   * @description Gets the validation rules from a given field.
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Array.<String>} ruleStrings - an array of all the rules for
   * a field. Null if none.
   *
   */
  _rulesOn(field) {
    const ruleString = field.dataset.requiredFieldRule
    if (ruleString) {
      return ruleString.split(' ')
    } else {
      return null
    }
  }

  /**
   * @function Controllers.RequiredFieldController._presenceRule
   * @memberof Controllers
   * @description Validates that a simple field has something in it or that one
   * of a radio cluster is checked.
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _presenceRule(field) {
    if (field.dataset.radio === 'true') {
      const radios = field.getElementsByTagName('input')
      return Array.from(radios).some(radio => radio.checked)
    } else if ([ 'text', 'textarea', 'email', 'tel', 'select-one' ].includes(field.type) && field.value !== '') {
      return true
    } else {
      return false
    }
  }

  /**
   * @function Controllers.RequiredFieldController._moneyRule
   * @memberof Controllers
   * @description Validates that the string is all digits and commas from beginning to end.
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _moneyRule(field) {
    return field.value.match(/^[\d,]+$/) !== null
  }
  /**
   * @function Controllers.RequiredFieldController._formatFullDateRule
   * @memberof Controllers
   * @description Validates the format of a full date, mm/dd/yyyy.
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _formatFullDateRule(field) {
    return field.value.match(/\d{2}\/\d{2}\/\d{4}/) !== null
  }

  /**
   * @function Controllers.RequiredFieldController._formatMonthYearDateRule
   * @memberof Controllers
   * @description Validates the format of a full date, mm/dd/yyyy.
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _formatMonthYearDateRule(field) {
    return field.value.match(/\d{2}\/\d{4}/) !== null
  }

  /**
   * @function Controllers.RequiredFieldController._ssnRule
   * @memberof Controllers
   * @description Validates the format of an ssn, ddd-dd-dddd.
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _ssnRule(field) {
    return field.value.match(/\d{9}/) !== null
  }

  /**
   * @function Controllers.RequiredFieldController._shortSsnRule
   * @memberof Controllers
   * @description Validates the format of an ssn, ddd-dd-dddd.
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _shortSsnRule(field) {
    return field.value.match(/\d{4}/) !== null
  }

  /**
   * @function Controllers.RequiredFieldController._emailRule
   * @memberof Controllers
   * @description Validates the format of an email address. This is the js
   * version of URI::MailTo::EMAIL_REGEXP we're using on the ruby side.
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _emailRule(field) {
    return field.value.match(/^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) !== null && // eslint-disable-line
      field.value.match(/\.\w{2,}$/) !== null
  }

  /**
   * @function Controllers.RequiredFieldController._phoneRule
   * @memberof Controllers
   * @description Validates a phone number: '(800) 383-1234'
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _phoneRule(field) {
    return field.value.match(/^\(\d{3}\) \d{3}-\d{4}$/) !== null
  }

  /**
   * @function Controllers.RequiredFieldController._zipRule
   * @memberof Controllers
   * @description Validates a zip code: '12345'
   * @private
   *
   * @param {element} field - the field in question
   *
   * @return {Boolean} fieldValidity - Boolean of field validity
   *
   */
  _zipRule(field) {
    return field.value.match(/^\d{5}$/) !== null
  }
}
