import { Controller } from 'stimulus'

/**
 * Manages js for front-end validated fields (StimulusJS controller)
 *
 * @class RecaptchaController
 * @memberof Controllers
 *
 * @example
 *
 * -# Example Markup (HAML)
 *
 *    On a form:
 *      = form_with scope: :form_name, html: { data: { controller: 'recaptcha', 'recaptcha-key': site_key, 'recaptcha-form': 'form_name' } } do |f|
 *
 *    In Ruby:
 *      site_key = Rails.application.credentials.dig(Rails.env.to_sym, :recaptcha_site_key)
 *
 *    On the form's submit:
 *        data: { action: 'recaptcha#maybeHuman' }
 */
export default class extends Controller {
  static targets = [ 'token' ]

  /**
   * @function Controllers.RecaptchaController.initialize
   * @memberof Controllers
   * @description Sets class variables to be used by functions and runs init functions.
   *
   */
  initialize() {
    this.siteKey = this.data.get('key')
    this.formName = this.data.get('form')

    this._recaptchaMaybeInit()
  }

  /**
   * @function RecaptchaController.connect
   * @description Called when Controller attaches
   */
  connect() {
    this._disableAjaxSubmit()
  }

  /**
   * @function Controllers.RecaptchaController.maybeHuman
   * @memberof Controllers
   * @description Called by forms needing captcha validation.
   *
   * @param {Event} event
   *
   * @return {undefined} manipulates the DOM
   *
   */
  maybeHuman(event) {
    event.preventDefault()
    this._appendHiddenInput() // only append the token input during submit to bypass the 2 min timeout

    /* global grecaptcha */
    window['grecaptcha'].ready(function() {
      grecaptcha.execute(this.siteKey, { action: 'submit' }).then(function(token) {
        this.tokenTarget.value = token
        const form = this.element

        if (form.requestSubmit) {
          form.requestSubmit()
        } else {
          form.submit()
        }
      }.bind(this))
    }.bind(this))
  }

  /**
   * @function Controllers.RecaptchaController._recaptchaMaybeInit
   * @memberof Controllers
   * @description Connects to the recaptcha api.
   * @private
   *
   * @return {undefined} manipulates the DOM
   *
   */
  _recaptchaMaybeInit() {
    if ( window['grecaptcha']) { return }

    const tag = document.createElement('script')
    tag.id = 'recaptcha-js-api'
    tag.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`
    this.element.appendChild(tag)
  }

  /**
   * @function Controllers.RecaptchaController._appendHiddenInput
   * @memberof Controllers
   * @description Adds a hidden input where the recaptcha tokan can be attached.
   * @private
   *
   * @return {undefined} manipulates the DOM
   *
   */
  _appendHiddenInput() {
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', `${this.formName}[recaptcha_token]`)
    hiddenInput.setAttribute('data-target', 'recaptcha.token')
    this.element.appendChild(hiddenInput)
  }

  /**
   * @function Controllers.RecaptchaController._disableAjaxSubmit
   * @memberof Controllers
   * @description Disables the permanent disabling of the ajax functionality.
   * @private
   *
   * @return {undefined} manipulates the DOM
   *
   */
  _disableAjaxSubmit() {
    delete this.element.dataset.remote
    for (const input of this.element.getElementsByTagName('input')) {
      if (input.type === 'submit') {
        delete input.dataset.disableWith
      }
    }
  }
}
