import { Controller } from 'stimulus'
import { XHR } from 'classes/XHR'

/**
 * @class LoadMoreItemsController
 * @extends Controllers
 */
export default class extends Controller {
  static targets = [ 'output', 'button', 'dummy' ]

  /**
   * @function LoadMoreItemsController.loadAdditionalContent
   * @param {event} event
   */
  loadAdditionalContent(event) {
    event.preventDefault()
    this._disableButton()

    const url = this._urlWithPageParam()
    const xHR = new XHR

    xHR.get(url).then(response => {
      if (response !== ' ') {
        this._addAdditionalContent(response)
        this._enableButton()
      } else {
        this._uponReviewExhaustion()
      }
    }, error => {})
  }

  //
  // "Private" methods
  //

  /**
   * @private
   */
  _uponReviewExhaustion() {
    this.buttonTarget.innerText = this.exhaustedButtonText
    this._disableButton()
  }

  /**
   * @param {string} responseText
   * @private
   */
  _addAdditionalContent(responseText) {
    const additionalContent = this._maybePrepareDummy(responseText)

    this.outputTarget.innerHTML += additionalContent
    this._incrementPage()
  }

  /**
   * @param {string} responseText
   * @return {string}
   * @private
   */
  _maybePrepareDummy(responseText) {
    if (this.hasDummyTarget) {
      const dummySpacer = this.dummyTarget
      const dummiedResponseText = responseText + dummySpacer.outerHTML

      dummySpacer.parentNode.removeChild(dummySpacer)

      return dummiedResponseText
    }

    return responseText
  }

  /**
   * @private
   */
  _disableButton() {
    this.buttonTarget.setAttribute('disabled', true)
  }

  /**
   * @private
   */
  _enableButton() {
    this.buttonTarget.removeAttribute('disabled')
  }

  /**
   * @return {string}
   * @private
   */
  _urlWithPageParam() {
    return this.url + `?page=${this.page}`
  }

  /**
   * @private
   */
  _incrementPage() {
    this.page++
  }

  //
  // Getters/Setters
  //

  /**
   * @return {number}
   */
  get page() {
    return parseInt(this.data.get('page'))
  }

  /**
   * @param {number} value
   * @private
   */
  set page(value) {
    this.data.set('page', value)
  }

  /**
   * @return {string}
   * @private
   */
  get url() {
    return this.data.get('url')
  }

  /**
   * @return {string}
   * @private
   */
  get exhaustedButtonText() {
    return this.data.get('exhausted-button-text')
  }
}
