import { Controller } from 'stimulus'

/**
 * @class ContentExpandController
 * @extends Controllers
 */
export default class extends Controller {
  static targets = [
    'toggleElement',
    'titleElement'
  ]

  /**
   * @function ContentExpandController.toggleExpand
   */
  toggleExpand() {
    const toggleClass = this.data.get('toggle-class')
    const elementToToggle = this.hasToggleElementTarget ? this.toggleElementTarget : this.element
    elementToToggle.classList.toggle(toggleClass)

    this.hasTitleElementTarget ? this.titleElementTarget.classList.toggle('accordion__cross--hidden') : null
  }
}
