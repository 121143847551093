/**
 * Parse Float String
 *
 * @param {string} value - Float in string format
 *
 * @return {number}
 */
export default function(value) {
  if ((typeof value === 'string' || value instanceof String) && value.match(/\d/)) {
    return parseFloat(value.replace(/,/g, ''))
  } else if (!isNaN(value)) {
    return parseFloat(value)
  } else {
    return NaN
  }
}
