import { Controller } from 'stimulus'

/** How We Hire */
export default class extends Controller {
  static targets=[ 'indicator' ]

  /** Initializes the How We Hire controller */
  initialize() {
    this.activationThreshold = 0
    this.resize()
  }

  /** Refresh activationThreshold */
  resize() {
    this.activationThreshold = window.innerHeight / 3
  }

  /** Change color based on scroll position */
  scroll() {
    this.indicatorTargets.forEach(indicator => {
      if (indicator.getBoundingClientRect().y <= this.activationThreshold) {
        indicator.classList.add('how-we-hire__progress-section--active')
      } else {
        indicator.classList.remove('how-we-hire__progress-section--active')
      }
    })
  }
}
