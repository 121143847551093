import { Controller } from 'stimulus'

/**
 * Delays the insertion of the template document fragment contents
 *
 * @class DelayLoaderController
 * @extends Controllers
 * @example
 * -# Example Markup (HAML)
 *
 * %span#example-name{ 'data-controller' => 'delay-loader' }
 *   %input{ type: :button, 'data-action': 'delay-loader#load' }
 *   %template{ 'data-target': 'delay-loader.template' }
 *     %h1 Swapped!
 *     %p This content will be swapped in on the first button click!
 *     %p note, the following is required
 *     %ul
 *       %li an id on the element with 'data-controller'
 *       %li a template tag with 'data-target': 'delay-loader.template'
 */
export default class extends Controller {
  static targets = ['template']

  /**
   * @function DelayLoaderController.connect
   * @description Called when Controller attaches after Turbolinks:Load
   */
  connect() {
    if (this._id === '') {
      console.log('[delay-loader] Missing id on base element!')
    } else if (this._shouldLoad) {
      this.load()
    } else {
      this._showContainer()
    }
  }

  /**
   * @function DelayLoaderController.load
   */
  load() {
    if (this._id !== '' && this.hasTemplateTarget) {
      if (this.templateTarget.content) {
        const newContent = this.templateTarget.content.cloneNode(true)
        this.element.innerHTML = ''
        this.element.appendChild(newContent)
        sessionStorage.setItem(this._sessionKey, 'true')
        this._showContainer()
      }
    }
  }

  //
  // "Private" methods
  //

  /**
   * @return {string}
   * @private
   */
  get _id() {
    return this.element.id
  }

  /**
   * @return {string}
   * @private
   */
  get _sessionKey() {
    return `${this.identifier}-${this._id}`
  }

  /**
   * @return {boolean}
   * @private
   */
  get _shouldLoad() {
    return this._id !== '' &&
      sessionStorage.getItem(this._sessionKey) === 'true'
  }


  /**
   * @description Set parent element as visible
   * @private
   */
  _showContainer() {
    this.element.style.opacity = 1
  }
}
