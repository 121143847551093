/**
 * Waits for an id, class or attribute on document to become available
 *
 * @class DocumentWaitFor
 * @example
 *   new DocumentWaitFor('body', () => console.log('body is present'))
 *   new DocumentWaitFor('.card--important', () => {
 *     // important card processing...
 *     console.log('we now have at least 1 important card.')
 *   })
 *   new DocumentWaitFor('#dynamic-footer', () => {
 *     // dynamic footer stuff
 *     console.log('dynamic footer available for processing')
 *   })
 */
export default class DocumentWaitFor {
  /**
   * Constructor
   *
   * @constructs
   * @param { String } thing - id, class, or document attribute
   * @param { Function } func - Function to execute once target is present
   */
  constructor(thing, func) {
    if (thing === undefined || thing.length < 1) {
      console.log('DocumentWaitFor constructor needs a string')
      return
    } else if (func === undefined) {
      console.log('DocumentWaitFor constructor needs a function to execute')
    }

    this._initializeCheckFor(thing)
    const observer = new MutationObserver((mutations, me) => {
      if (this.check()) {
        func()
        me.disconnect()
        return
      }
    })

    observer.observe(document, { childList: true, subtree: true })
  }


  // "private"


  /**
   * Initialize check function, assigns this.check()
   *
   * @param {String} thing - id, class, or document attribute
   * @private
   */
  _initializeCheckFor(thing) {
    const prefix = thing.charAt(0)
    const rest = thing.substring(1)

    switch (prefix) {
      case '#':
        this.check = () => { return document.getElementById(rest) !== null }
        break
      case '.':
        this.check = () => { return document.getElementsByClassName(rest).length > 0 }
        break
      default:
        this.check = () => { return document[thing] !== undefined && document[thing] !== null }
    }
  }
}
