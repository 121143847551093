import { Controller } from 'stimulus'

/**
 * Manages js for the toggle visibility functionality (StimulusJS controller)
 *
 * @class ToggleVisibilityController
 * @memberof Controllers
 */
export default class extends Controller {
  static targets = [ 'togglable' ]

  /**
   * @function Controllers.ToggleVisibilityController.connect
   * @memberof Controllers
   * @description Called on page load. Toggles visibility of togglable targets.
   */
  connect() {
    this.cssName = this.data.get('cssClass')

    this.addGlobalRadioDeselectNotification()

    this.togglableTargets.forEach(togglable => {
      const id = togglable.dataset.toggleVisibilityId
      const target = document.getElementById(id)
      this.toggleVisibility(target)
    })
  }

  /**
   * @function Controllers.ToggleVisibilityController.addGlobalRadioDeselectNotification
   * @memberof Controllers
   * @description Called on page load. Adds click listener for global radio deselects.
   */
  addGlobalRadioDeselectNotification() {
    const inputs = Array.from(this.element.getElementsByTagName('input'))
    const radios = inputs.filter(input => input.type === 'radio')

    this.element.addEventListener('click', event => {
      const target = event.target
      if (target.type !== 'radio') {
        return
      }

      radios.forEach(radio => {
        if (radio.id !== target.id && radio.name === target.name) {
          radio.dispatchEvent(new CustomEvent('change'))
        }
      })
    })
  }

  /**
   * @function Controllers.ToggleVisibilityController.toggle
   * @memberof Controllers
   * @description Called on page load. Toggles visibility for single event.
   *
   * @param {Event} event
   */
  toggle(event) {
    const target = event.target
    this.toggleVisibility(target)
  }

  /**
   * @function Controllers.ToggleVisibilityController.toggleVisibility
   * @memberof Controllers
   * @description Called on page load. Toggles visibility of togglable targets.
   *
   * @param {Target} target
   */
  toggleVisibility(target) {
    this.togglableTargets.forEach(togglable => {
      if (togglable.dataset.toggleVisibilityId !== target.id) {
        return
      }

      if (target.checked) {
        togglable.classList.remove(this.cssName)
      } else {
        togglable.classList.add(this.cssName)
      }
    })
  }
}
