import IMask from 'imask'

/**
 * @class InputMasking
 */
export class InputMasking {
  /**
   * @static
   */
  static applyAll() {
    this.applySSNFields()
    this.applySSNShortFields()
    this.applyNumberFields()
    this.applyPhoneFields()
    this.applyDateFields()
    this.applyZipCodeFields()
    this.applyMoneyFields()
    this.applyNameFields()
    this.applyPercentageFields()
  }

  /**
   * @static
   */
  static start() {
    this.applyAll()

    document.addEventListener('turbolinks:load', function() {
      this.applyAll()
    }.bind(this))
  }

  /**
   * @static
   */
  static applyNumberFields() {
    const numberFields = document.querySelectorAll('[data-behavior*="input-masking-number"]')

    numberFields.forEach(field => {
      const maskOptions = { mask: Number }
      this._applyMaskTo(field, maskOptions)
    })
  }

  /**
   * @static
   */
  static applyPhoneFields() {
    const phoneFields = document.querySelectorAll('[data-behavior*="input-masking-phone"]')

    phoneFields.forEach(field => {
      const maskOptions = {
        mask:            '(000) 000-0000',
        lazy:            true, // make placeholder sometimes visible
        placeholderChar: ' ' // defaults to '_'
      }
      this._applyMaskTo(field, maskOptions)
    })
  }

  /**
   * @static
   */
  static applySSNFields() {
    const ssnFields = document.querySelectorAll('[data-behavior*="input-masking-ssn"]')

    ssnFields.forEach(field => {
      const maskOptions = {
        mask: '000000000'
      }
      this._applyMaskTo(field, maskOptions)
    })
  }

  /**
   * @static
   */
  static applySSNShortFields() {
    const ssnFields = document.querySelectorAll('[data-behavior*="input-masking-short-ssn"]')

    ssnFields.forEach(field => {
      const maskOptions = {
        mask: '0000'
      }
      this._applyMaskTo(field, maskOptions)
    })
  }

  /**
   * @static
   */
  static applyDateFields() {
    const dateFields = document.querySelectorAll('[data-behavior*="input-masking-date"]')

    dateFields.forEach(field => {
      // Custom date format for 00/00/0000 - https://unmanner.github.io/imaskjs/guide.html#date
      const dateFormat = field.dataset.dateFormat
      const formatOptions = {
        mask:    Date,
        pattern: 'm/`d/`Y',
        min:     new Date(1900, 0, 1), // defaults to 1900-01-01
        max:     new Date(2100, 0, 1), // defaults to 9999-01-01
        format:  function(date) {
          let day    = date.getDate()
          let month  = date.getMonth() + 1
          const year = date.getFullYear()

          if (day < 10) { day = '0' + day }
          if (month < 10) { month = '0' + month }

          return [ month, day, year ].join('/')
        },
        // date conversion
        parse: function(dateString) {
          const yearMonthDay = dateString.split('/')

          return new Date(yearMonthDay[2], yearMonthDay[0] - 1, yearMonthDay[1])
        }
      }

      // Changes options based on date format
      if (dateFormat === 'm/`Y') {
        formatOptions['pattern'] = dateFormat
        formatOptions['format']  = date => {
          let month  = date.getMonth() + 1
          const year = date.getFullYear()

          if (month < 10) { month = '0' + month }

          return [ month, year ].join('/')
        }
        formatOptions['parse'] = dateString => {
          const yearMonth = dateString.split('/')

          return new Date(yearMonth[1], yearMonth[0] - 1, 1)
        }
        formatOptions['prepare'] = (v, masked) => {
          return (parseInt(v) > 1 && parseInt(v) < 10 && !masked.value) ? '0' : v
        }
      }

      this._applyMaskTo(field, formatOptions)
    })
  }

  /**
   * @static
   */
  static applyZipCodeFields() {
    const zipCodeFields = document.querySelectorAll('[data-behavior*="input-masking-zip-code"]')

    zipCodeFields.forEach(field => {
      const maskOptions = { mask: '00000' }
      this._applyMaskTo(field, maskOptions)
    })
  }

  /**
   * @static
   */
  static applyMoneyFields() {
    // Money input masking
    const moneyFields = document.querySelectorAll('[data-behavior*="input-masking-money"]')

    moneyFields.forEach(field => {
      const scale = (field.dataset.dollarOnly) ? 0 : 2
      const maskOptions = {
        mask:               Number,
        scale:              scale, // digits after point, 0 for integers
        signed:             false, // disallow negative
        thousandsSeparator: ',', // any single char
        padFractionalZeros: !field.dataset.dollarOnly, // if true, then pads zeros at end to the length of scale
        normalizeZeros:     true, // appends or removes zeros at ends
        radix:              '.', // fractional delimiter
        mapToRadix:         [ '.' ], // symbols to process as radix
        min:                0,
        max:                90000000000
      }
      this._applyMaskTo(field, maskOptions)
    })
  }

  /**
   * @static
   */
  static applyNameFields() {
    // Name input mask - e.g. first name, last name
    const nameFields = document.querySelectorAll('[data-behavior*="input-masking-name"]')

    nameFields.forEach(field => {
      const maskOptions = { mask: /^[A-Za-z-.\s']+$/ }
      this._applyMaskTo(field, maskOptions)
    })
  }

  /**
   * @param {object} field
   * @param {object} maskOptions
   * @static
   */
  static _applyMaskTo(field, maskOptions) {
    if (typeof field.imask !== 'undefined') {
      // we already have a mask applied, refresh it
      field.imask.value = field.value
    } else {
      field.imask = new IMask(field, maskOptions)
    }
  }

  /**
   * @static
   */
  static applyPercentageFields() {
    // Percentage input masking
    const percentageFields = document.querySelectorAll('[data-behavior*="input-masking-percentage"]')

    if (percentageFields) {
      for (let p = 0; p < percentageFields.length; p++) {
        // options: https://unmanner.github.io/imaskjs/guide.html#number
        new IMask(percentageFields[p], {
          mask:               Number,
          scale:              3, // digits after point, 0 for integers
          signed:             false, // disallow negative
          thousandsSeparator: '', // any single char
          padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
          normalizeZeros:     true, // appends or removes zeros at ends
          radix:              '.', // fractional delimiter
          mapToRadix:         [ '.' ], // symbols to process as radix
          min:                0,
          max:                100
        })
      }
    }
  }
}
