import { MarketingAttribution } from 'classes/marketing-attribution'

const behavior = 'maybe-form-marketing-values'

document.addEventListener('submit', event => {
  const element = event.target

  if (element.dataset.behavior && element.dataset.behavior.includes(behavior)) {
    const marketingAttribution = new MarketingAttribution

    if (marketingAttribution.sessionValuesPresent()) {
      const originalSourceField   = document.getElementById('form_original_source')
      const sourceField           = document.getElementById('form_source')
      const internetSourceField   = document.getElementById('form_internet_source')
      const originalEndorserField = document.getElementById('form_original_endorser')
      const endorserField         = document.getElementById('form_endorser')
      const gclidField            = document.getElementById('form_gclid')
      const msclkidField          = document.getElementById('form_msclkid')

      const glOriginalSourceField   = document.getElementById('gl_form_original_source')
      const glSourceField           = document.getElementById('gl_form_source')
      const glInternetSourceField   = document.getElementById('gl_form_internet_source')
      const glOriginalEndorserField = document.getElementById('gl_form_original_endorser')
      const glEndorserField         = document.getElementById('gl_form_endorser')
      const glGclidField            = document.getElementById('gl_form_gclid')
      const glMsclkidField          = document.getElementById('gl_form_msclkid')

      if (sourceField) {
        originalSourceField.value   = marketingAttribution.source
        sourceField.value           = marketingAttribution.source
        internetSourceField.value   = marketingAttribution.internetSource
        originalEndorserField.value = marketingAttribution.endorser
        endorserField.value         = marketingAttribution.endorser
        gclidField.value            = marketingAttribution.gclid
        msclkidField.value          = marketingAttribution.msclkid
      }

      if (glSourceField) {
        glOriginalSourceField.value   = marketingAttribution.source
        glSourceField.value           = marketingAttribution.source
        glInternetSourceField.value   = marketingAttribution.internetSource
        glOriginalEndorserField.value = marketingAttribution.endorser
        glEndorserField.value         = marketingAttribution.endorser
        glGclidField.value            = marketingAttribution.gclid
        glMsclkidField.value          = marketingAttribution.msclkid
      }
    }
  }
}, true)
