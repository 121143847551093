/**
 * @class MarketingAttribution
 */
export class MarketingAttribution {
  /**
   * Top level interface for persisting params
   */
  persistMarketingParams() {
    const queryObj = this._parseQuery(location.search.substring(1))

    this._storeMarketingValues(queryObj)
  }


  /**
   * @return {boolean}
   */
  sessionValuesPresent() {
    if (this.source && this.internetSource && this.endorser) {
      return true
    } else {
      return false
    }
  }


  /**
   * Persist the marketing values
   *
   * @param {object} queryObj
   * @fires 'marketing-values:provided'
   * @private
   */
  _storeMarketingValues(queryObj) {
    if (this._marketingValuesPresentInQuery(queryObj)) {
      this.source         = queryObj['src']
      this.internetSource = queryObj['isrc']
      this.endorser       = queryObj['end']
      this.gclid          = queryObj['gclid']
      this.msclkid        = queryObj['msclkid']
    }
  }


  /**
   * Parse query string into js object
   *
   * @param {string} uri
   * @return {object} queryObj
   * @private
   */
  _parseQuery(uri) {
    const decodedURI = decodeURIComponent(uri)
    const pairs = decodedURI.split('&')
    const queryObj  = {}

    pairs.forEach(pair => {
      const pieces = pair.split('=')

      queryObj[pieces[0]] = pieces[1]
    })

    return queryObj
  }


  /**
   * @param {object} queryObj
   * @return {boolean}
   * @private
   */
  _marketingValuesPresentInQuery(queryObj) {
    if (queryObj.src && queryObj.isrc && queryObj.end) {
      return true
    } else {
      return false
    }
  }


  /**
   * @return {string}
   * @private
   */
  get source() {
    return sessionStorage.getItem('source')
  }

  /**
   * @param {string} value
   * @private
   */
  set source(value) {
    sessionStorage.setItem('source', value.replace(/\+/g, ' '))
  }

  /**
   * @return {string}
   * @private
   */
  get internetSource() {
    return sessionStorage.getItem('internet_source')
  }

  /**
   * @param {string} value
   * @private
   */
  set internetSource(value) {
    sessionStorage.setItem('internet_source', value.replace(/\+/g, ' '))
  }

  /**
   * @return {string}
   * @private
   */
  get gclid() {
    return sessionStorage.getItem('gclid')
  }

  /**
   * @param {string} value
   * @private
   */
  set gclid(value) {
    sessionStorage.setItem('gclid', value)
  }

  /**
   * @return {string}
   * @private
   */
  get msclkid() {
    return sessionStorage.getItem('msclkid')
  }

  /**
   * @param {string} value
   * @private
   */
  set msclkid(value) {
    sessionStorage.setItem('msclkid', value)
  }

  /**
   * @return {string}
   * @private
   */
  get endorser() {
    return sessionStorage.getItem('endorser')
  }

  /**
   * @param {string} value
   * @private
   */
  set endorser(value) {
    sessionStorage.setItem('endorser', value.replace(/\+/g, ' '))
  }
}
