import DocumentWaitFor from 'classes/document-wait-for'

/**
 * @class AfcGtm
 */
class AfcGtm {
  /**
   * Constructor
   *
   * consoleLogger = true to enable browser console logging
   * __logGrouping = internal toggle used by logging methods
   * debounceThresholdMs = amount of time between gtm requests allowed
   */
  constructor() {
    this.consoleLogging = false
    this.__logGrouping = false
    this.debounceThresholdMs = 800
  }

  /**
   * Attaches a listener to `turbolinks:load` and
   * does an initial push to GTM on load
   */
  start() {
    this.log('<< start() >>', true, 'gold')
    this.log('attaching to turbolinks:load')
    this._attachListener()

    this.log('manually calling push method, outside event')
    // ensure body is exists for this first call, causes errors otherwise
    new DocumentWaitFor('body', () => { this.sendPageToGtm() })

    this.logEnd()
  }

  /**
   * Pushes `data` up to GTM
   *
   * @param {object} data - payload to send to GTM
   */
  push(data) {
    const layer = 'dataLayer'
    window[layer] = window[layer] || []
    window[layer].push(data)
  }

  /**
   * This is the main GTM `turboPageLoad` process that is called on each `turbolink:load` event
   */
  sendPageToGtm() {
    if (this._debounced) {
      this.log(`DEBOUNCED, last event ${this._lastPushAgoMs} ms ago`, false, 'red')
      return
    } else {
      this._registerLastPush()
    }

    this.log('➡️ gtm.start')
    this.push({
      'gtm.start': new Date().getTime(),
      'event':     'gtm.js'
    })

    // Long form code handles its own GTM push
    if ( !this._isInLongForms ) {
      const pageData = {
        'session_uuid': sessionStorage.getItem('sessionUUID'),
        'event':        'turboPageLoad'
      }

      this._maybeAddLearningCenterDataTo(pageData)

      this.push(pageData)
      this.log(`➡️ turboPageLoad: ${JSON.stringify(pageData)}`)
    } else {
      this.log('detected we\'re in a long form, skipping turboPageLoad')
    }
  }

  /**
   * Internal logging method, can be enabled with the toggle in the constructor
   *
   * @param {string} msg - Message to log in the console
   * @param {boolean} grouping - Whether or not we should start a new console group
   * @param {string} styling - CSS styling payload to apply, defined in `_style`
   */
  log(msg, grouping = false, styling = false) {
    if (!this.consoleLogging) { return }

    if (grouping) {
      this.logEnd()
      console.group(`[afc_gtm] %c ${msg}`, this._style(styling))
      this.__logGrouping = true
      return
    } else if (this.__logGrouping) {
      console.log('%c%s', this._style(styling), msg)
    } else {
      console.log(`[afc_gtm] %c ${msg}`, this._style(styling))
    }
  }

  /**
   * Internal logging helper, closes a console log group
   */
  logEnd() {
    if (!this.consoleLogging) { return }

    console.groupEnd()
  }

  /**
   * Attaches the `turbolinks:load` handler
   *
   * @private
   */
  _attachListener() {
    document.addEventListener('turbolinks:load', event => {
      this.log('turbolinks:load event', true)
      this.sendPageToGtm()
      this.logEnd()
    })
  }

  /**
   * Adds additional learning center specific data if currently on learning center pages
   *
   * @param {object} data - current GTM data payload
   * @private
   */
  _maybeAddLearningCenterDataTo(data) {
    const firstLevel = window.location.pathname.split('/')[1]
    // zero-ith element is always an empty string

    if ( this._isInLearningCenter ) {
      data['current_category'] = firstLevel
      data['page_type'] = 'learning-center'
    }
  }

  /**
   * Returns CSS matching requested style name
   *
   * @param {string} style - style name associated with CSS string
   * @return {string} css
   * @private
   */
  _style(style) {
    switch (style) {
      case 'gold': {
        return 'background: gold;'
      }
      case 'red': {
        return 'background: tomato;color:white;padding:1em;font-size:18px;'
      }
      default: { return '' }
    }
  }

  /**
   * Saves the timestamp of the last GTM push (in ms)
   */
  _registerLastPush() {
    const previousPushAgo = this._lastPushAgoMs
    sessionStorage.setItem('__afc-gtm-last', new Date().getTime())
    this.log(`last pushed ${previousPushAgo} ms ago, new registered @ ${this._lastPushMs}`)
  }

  /**
   * return {boolean} Is time since last GTM push more recent than our debounce threshold (in ms)
   */
  get _debounced() {
    return this._lastPushAgoMs <= this.debounceThresholdMs
  }

  /**
   * return {integer} Time since last GTM push (in ms)
   */
  get _lastPushAgoMs() {
    return new Date().getTime() - this._lastPushMs
  }

  /**
   * return {integer} Timestamp of last GTM push (in ms)
   */
  get _lastPushMs() {
    return sessionStorage.getItem('__afc-gtm-last')
  }

  /**
   * return {boolean} Are we currently on a Learning Center page
   */
  get _isInLearningCenter() {
    const usingLayout = document.body.classList.value.includes('body-learning-center')
    const exemptions = [ '/search' ]

    return usingLayout && !exemptions.includes(window.location.pathname)
  }

  /**
   * return {boolean} Are we currently on a DM Application or Goldy page
   */
  get _isInLongForms() {
    const path = window.location.pathname.split('/').slice(0, 3).join('/')
    const longForms = [
      '/apply/purchase',
      '/apply/refinance',
      '/apply/short-purchase',
      '/apply/short-refinance'
    ]

    return longForms.includes(path)
  }
}

export default new AfcGtm
