import { Controller } from 'stimulus'

/**
 * Auto clicks attached element
 *
 * @class AutoclickController
 * @memberof Controllers
 */
export default class extends Controller {
  connect() {
    this.element.click()
  }
}
